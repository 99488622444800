/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { SetStateAction } from 'react';
import { useSelector } from 'react-redux';
import { userColourPaletteActive } from '@appCanvas/interfaces/types/ColourPalette.type';
import { ResultTableStyle } from '@webOrganisms/ResultTable/ResultTableContext';
import {
  GalleryFilter,
  getDefaultGalleryFilter,
} from '@interfaces/GalleryFilter';
import { selectFilterId } from '@selectors/FindingInteractionSelectors';
import { useDispatcher } from '@helpers/useDispatcher';
import { findingInteractionUseCase } from '@useCases/FindingInteraction';
import { Workflow } from '@entities/Workflow';
import { selectStudyType } from '@selectors/SessionSelectors';
import { cnames } from '@helpers/cnames';
import { ScrollBox } from '@webMolecules/ScrollBox/ScrollBox';
import { ResizableSplitPanel } from '@webMolecules/ResizableSplitPanel/ResizableSplitPanel';
import GalleryWrapper from '../GalleryWrapper';
import ReportDiagramWrapper from '../Diagram/ReportDiagramWrapper';
import FindingsTableWrapper from '../FindingsTableWrapper';
import { MiniCanvasProvider } from '../MiniCanvasContext';
import FindingFilterBar from '../FindingFilterBar/FindingFilterBar';

export const RadiologySession: React.FC = () => {
  const removeFilter = useDispatcher(findingInteractionUseCase.RemoveFilter);
  const deselectActiveFinding = useDispatcher(
    findingInteractionUseCase.Deselect
  );
  const filterId = useSelector(selectFilterId);
  const studyType = useSelector(selectStudyType);

  const [galleryFilter, setGalleryFilter] = React.useState<GalleryFilter>(
    getDefaultGalleryFilter(studyType, true)
  );

  const setGalleryFilterWhenSelected: React.Dispatch<
    React.SetStateAction<GalleryFilter>
  > = (filter: SetStateAction<GalleryFilter>) => {
    if (!!filterId) {
      removeFilter();
    } else {
      setGalleryFilter(filter);
    }
  };

  const galleryFilterWhenSelected = !!filterId
    ? { configKeys: [], findingIds: [filterId] }
    : galleryFilter;

  return (
    <div className="flex size-full" onClick={deselectActiveFinding}>
      <ResizableSplitPanel
        minLeftWidth={{ pixels: 450, percentage: 25, preference: 'max' }}
        minRightWidth={{ pixels: 550, percentage: 25, preference: 'max' }}
        leftChildren={
          <GalleryWrapper
            loading={false}
            setGalleryFilter={setGalleryFilterWhenSelected}
            filters={galleryFilterWhenSelected}
            includeAllDicoms
            workflow={Workflow.Radiologist}
            useFindingGalleryThumbnail
          />
        }
        rightChildren={
          <div className="flex size-full flex-col">
            <div className="px-xl">
              <FindingFilterBar />
            </div>
            <ScrollBox className="px-xl pb-xxxxl pt-s" axis="y">
              <MiniCanvasProvider>
                <ScrollBox className="mt-xs pb-m" axis="x">
                  <ReportDiagramWrapper
                    canvasColorPalette={userColourPaletteActive}
                    showCanvasOnly={true}
                    disableInteractions={false}
                  />
                </ScrollBox>
                <div className="mt-s">
                  <FindingsTableWrapper
                    applyFindingFilter={false}
                    resultTableStyle={ResultTableStyle.editable}
                  />
                </div>
              </MiniCanvasProvider>
            </ScrollBox>
          </div>
        }
      />
    </div>
  );
};
