import React, {
  useEffect,
  useRef,
  useState,
  useCallback,
  createContext,
} from 'react';
import { setCursor } from './ResizeCursors';

interface ResizableSplitPanelContextProps {
  isResizing: boolean;
}

export const ResizableSplitPanelContext =
  createContext<ResizableSplitPanelContextProps>({ isResizing: false });

type PanelWidth =
  | { pixels: number }
  | { percentage: number }
  | {
      pixels: number;
      percentage: number;
      preference: 'min' | 'max';
    };

interface ResizableSplitPanelProps {
  leftChildren: React.ReactNode;
  rightChildren: React.ReactNode;
  initialLeftWidth?: PanelWidth;
  minLeftWidth?: PanelWidth;
  minRightWidth?: PanelWidth;
}

export const ResizableSplitPanel: React.FC<ResizableSplitPanelProps> = ({
  leftChildren,
  rightChildren,
  initialLeftWidth = { percentage: 50 },
  minLeftWidth = { percentage: 25 },
  minRightWidth = { percentage: 25 },
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [leftWidthPercentage, setLeftWidthPercentage] = useState(50);
  const [isDragging, setIsDragging] = useState(false);
  const dividerWidth = 6; // in pixels

  const handleMouseDown = useCallback((e: React.MouseEvent) => {
    if (e.button !== 0) return;

    setIsDragging(true);
    setCursor('col-resize');
    document.body.style.userSelect = 'none';

    // Prevent default behavior to stop text selection
    e.preventDefault();
  }, []);

  const handleMouseMove = useCallback(
    (event: MouseEvent) => {
      if (!containerRef.current) return;

      const rect = containerRef.current.getBoundingClientRect();
      const mouseX = event.clientX - rect.left;
      const containerWidth = rect.width;

      const newLeftWidth = (mouseX / containerWidth) * 100;

      const minLeftWidthPercentage = getPercentageWidth(
        minLeftWidth,
        containerWidth
      );
      const minRightWidthPercentage = getPercentageWidth(
        minRightWidth,
        containerWidth
      );

      const clampedPercentage = Math.max(
        minLeftWidthPercentage,
        Math.min(100 - minRightWidthPercentage, newLeftWidth)
      );

      // Update cursor based on limits
      if (clampedPercentage === minLeftWidthPercentage) {
        setCursor('e-resize');
      } else if (clampedPercentage === 100 - minRightWidthPercentage) {
        setCursor('w-resize');
      } else {
        setCursor('col-resize');
      }

      // Throttle width updates to refresh rate
      requestAnimationFrame(() => {
        setLeftWidthPercentage(clampedPercentage);
      });
    },
    [minLeftWidth, minRightWidth]
  );

  const handleMouseUp = useCallback(() => {
    setIsDragging(false);
    setCursor('');
    document.body.style.userSelect = '';
  }, []);

  useEffect(() => {
    if (isDragging) {
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    }

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDragging, handleMouseMove, handleMouseUp]);

  useEffect(() => {
    // Set initial width
    if (containerRef.current) {
      const rect = containerRef.current.getBoundingClientRect();
      const containerWidth = rect.width;

      const leftWidthPercentage = getPercentageWidth(
        initialLeftWidth,
        containerWidth
      );
      setLeftWidthPercentage(leftWidthPercentage);
    }
  }, []);

  const leftWidth = `calc(${leftWidthPercentage}% - ${dividerWidth / 2}px)`;
  const rightWidth = `calc(${100 - leftWidthPercentage}% - ${dividerWidth / 2}px)`;

  return (
    <ResizableSplitPanelContext.Provider value={{ isResizing: isDragging }}>
      <div
        className="flex size-full max-w-full items-stretch overflow-hidden"
        ref={containerRef}
      >
        <div
          className="h-full flex-none overflow-hidden"
          style={{ width: leftWidth }}
        >
          {leftChildren}
        </div>
        <div
          className="group relative h-full flex-none cursor-col-resize touch-none transition-colors"
          style={{ width: `${dividerWidth}px` }}
          onMouseDown={handleMouseDown}
        >
          <div className="absolute inset-x-[1px] h-full bg-neutral-700 group-hover:bg-neutral-600" />
          <div
            className={`absolute size-full transition-colors ${isDragging ? 'bg-neutral-500' : 'bg-transparent'}`}
          />
          <div
            className={`absolute inset-x-[2px] top-1/2 h-50 w-[2px] -translate-y-1/2 rounded-pill transition-colors
              ${isDragging ? 'bg-neutral-900' : 'bg-neutral-400'}`}
          />
        </div>
        <div
          className="h-full flex-none overflow-hidden"
          style={{ width: rightWidth }}
        >
          {rightChildren}
        </div>
      </div>
      {isDragging && <BlockPointerEventsOverlay onMouseLeave={handleMouseUp} />}
    </ResizableSplitPanelContext.Provider>
  );
};

const BlockPointerEventsOverlay = ({
  onMouseLeave,
}: {
  onMouseLeave: () => void;
}) => {
  return (
    <div
      className="fixed left-0 top-0 z-[99] size-full"
      onMouseLeave={onMouseLeave}
    />
  );
};

function getPercentageWidth(
  panelWidth: PanelWidth,
  containerWidth: number
): number {
  if ('pixels' in panelWidth && 'percentage' in panelWidth) {
    const { pixels, percentage, preference } = panelWidth;
    const pixelPercentage = (pixels / containerWidth) * 100;
    if (preference === 'min') {
      return Math.min(pixelPercentage, percentage);
    } else {
      return Math.max(pixelPercentage, percentage);
    }
  } else if ('pixels' in panelWidth) {
    return (panelWidth.pixels / containerWidth) * 100;
  } else {
    return panelWidth.percentage;
  }
}
