const leftCursorSvg = encodeURIComponent(`
  <svg width="15" height="20" viewBox="0 0 15 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g filter="url(#filter0_d_2873_2043)">
  <path d="M9.02 1V7.01H8V3.14L2 9.01L8 14.9V10.99H9.02V17H13V1H9.02Z" fill="white"/>
  <path d="M9.99991 2V8H6.97991V5.76L3.65991 9.01L6.97991 12.23V9.98H9.99991V15.98H11.9799V2H9.99991Z" fill="black"/>
  </g>
  <defs>
  <filter id="filter0_d_2873_2043" x="0.2" y="0.2" width="14.6" height="19.6" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
  <feFlood flood-opacity="0" result="BackgroundImageFix"/>
  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
  <feOffset dy="1"/>
  <feGaussianBlur stdDeviation="0.9"/>
  <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.65 0"/>
  <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2873_2043"/>
  <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2873_2043" result="shape"/>
  </filter>
  </defs>
  </svg>
`);

const rightCursorSvg = encodeURIComponent(`
  <svg width="15" height="20" viewBox="0 0 15 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g filter="url(#filter0_d_2873_2037)">
  <path d="M2 1V17H5.98V10.99H7.04V14.46L13 8.99L7.04 3.53V7H5.98V1H2Z" fill="white"/>
  <path d="M5.00002 15.98V9.98002H8.02002V12.24L11.34 8.99002L8.02002 5.77002V8.02002H5.00002V2.02002H3.02002V15.98H5.00002Z" fill="black"/>
  </g>
  <defs>
  <filter id="filter0_d_2873_2037" x="0.2" y="0.2" width="14.6" height="19.6" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
  <feFlood flood-opacity="0" result="BackgroundImageFix"/>
  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
  <feOffset dy="1"/>
  <feGaussianBlur stdDeviation="0.9"/>
  <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.65 0"/>
  <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2873_2037"/>
  <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2873_2037" result="shape"/>
  </filter>
  </defs>
  </svg>
`);

export const setCursor = (
  cursorType: 'col-resize' | 'w-resize' | 'e-resize' | ''
) => {
  let cursorStyle: string;
  if (cursorType === 'w-resize') {
    cursorStyle = `url("data:image/svg+xml,${leftCursorSvg}") 12 12, w-resize`;
  } else if (cursorType === 'e-resize') {
    cursorStyle = `url("data:image/svg+xml,${rightCursorSvg}") 12 12, e-resize`;
  } else if (cursorType === 'col-resize') {
    cursorStyle = 'col-resize';
  } else {
    cursorStyle = '';
  }
  if (cursorStyle) {
    document.body.style.setProperty('cursor', cursorStyle, 'important');
  } else {
    requestAnimationFrame(() => {
      document.body.style.removeProperty('cursor');
    });
  }
};
