import React from 'react';
import { useSelector } from 'react-redux';
import { Dicom } from '@interfaces/Dicom';
import { Box } from '@webMolecules/Box/Box';
import { Card } from '@webMolecules/Card/Card';
import { Text } from '@webMolecules/Text/Text';
import { Image } from '@webMolecules/Image/Image';
import { GridType } from '@webViewModels/Pages/Study/GalleryHelper';
import { GalleryFilter } from '@interfaces/GalleryFilter';
import { useImageContext } from '@hooks/useImageContext';
import { Position } from '@webMolecules/PositionedBox/PositionedBox';
import { DialogConfirm } from '@webOrganisms/DialogConfirm/DialogConfirm';
import { t, tPrint } from '@webInterfaces/I18n';
import {
  eventFindingActionAssignedDetection,
  eventFindingActionReassignedDetection,
  eventFindingActionUnassignedDetection,
} from '@webInterfaces/Analytics';
import { findingInteractionUseCase } from '@useCases/FindingInteraction';
import { useDispatcher } from '@helpers/useDispatcher';
import { selectIsSize3D } from '@selectors/SessionSelectors';
import { Detection } from '@entities/Detection';
import { findingUseCase } from '@useCases/Finding';
import { Finding } from '@entities/Finding';
import styles from './gallery.scss';
import { DebugOverlay } from './DebugGalleryOverlay';
import { ThumbnailCanvas } from './ThumbnailCanvas';
import { FindingMenu } from './FindingMenu';
import { ThumbnailIcon } from './ThumbnailIcon';

export interface FindingThumbnailProps {
  isFullscreen?: boolean;
  handleSetView?: (type: GridType, index: number) => void;
  view: string;
  index: number;
  dicom: Dicom;
  debugDicom: boolean;
  filters: GalleryFilter;
  showSegmentationOverlay: boolean;
  showMeasurements: boolean;
}

export const FindingGalleryThumbnail = (props: FindingThumbnailProps) => {
  const {
    isFullscreen = false,
    handleSetView = () => null,
    view,
    index,
    dicom,
    debugDicom,
    filters,
    showSegmentationOverlay,
    showMeasurements,
  } = props;
  const resetInteractions = useDispatcher(findingInteractionUseCase.Reset);
  const assignDetection = useDispatcher(findingUseCase.AssignDetection);
  const unassignDetection = useDispatcher(findingUseCase.UnassignDetection);
  const createFindingFromDetection = useDispatcher(
    findingUseCase.CreateFindingFromDetection
  );

  const isSize3D = useSelector(selectIsSize3D);

  const { getImage, isLoadingImages } = useImageContext();
  const imageSrc = getImage(dicom.id);

  const [lastUnassignedDetection, setLastUnassignedDetection] =
    React.useState(false);
  const [showMenu, setShowMenu] = React.useState(false);
  const [menuPosition, setMenuPosition] = React.useState<Position | undefined>(
    undefined
  );

  const [activeDetectionId, setActiveDetectionId] = React.useState<
    string | null
  >(null);

  const activeDetection = dicom.detections.find(
    d => d.id === activeDetectionId
  );

  if (!imageSrc) {
    return <Box className={styles.smallPartsThumbnailSkeleton} />;
  }

  const detections = getDetectionsForThumbnail(dicom, isSize3D);

  const handleUpdateFinding = (finding: Finding) => {
    if (!activeDetection) return;

    if (
      !activeDetection.assignedFinding ||
      activeDetection.assignedFinding.id !== finding.id
    ) {
      if (!activeDetection.assignedFinding) {
        eventFindingActionAssignedDetection(
          finding.autoGenerated,
          dicom.sopInstanceUid
        );
      } else {
        eventFindingActionReassignedDetection(
          finding.autoGenerated,
          dicom.sopInstanceUid
        );
      }
      assignDetection({
        findingId: finding.id,
        detectionId: activeDetection.id,
      });
    }
  };

  const handleAddFinding = (detection: Detection) => {
    setShowMenu(false);
    createFindingFromDetection({ detectionId: detection.id });
  };

  const handleUnassignFromFinding = () => {
    setShowMenu(false);
    if (activeDetection && activeDetection.assignedFinding) {
      eventFindingActionUnassignedDetection(
        activeDetection.assignedFinding.autoGenerated,
        dicom.sopInstanceUid
      );
      unassignDetection({
        findingId: activeDetection.assignedFinding.id,
        detectionId: activeDetection.id,
      });
    }
  };

  const matchingFindingIndex = activeDetection?.assignedFinding?.index;

  if (lastUnassignedDetection && matchingFindingIndex) {
    return (
      <DialogConfirm
        isOpen={lastUnassignedDetection}
        onConfirm={() => {
          handleUnassignFromFinding();
          setLastUnassignedDetection(false);
          resetInteractions();
        }}
        onCancel={() => {
          setLastUnassignedDetection(false);
          setShowMenu(false);
        }}
        labelOk={t(`gallery.finding.dialog.menu.delete`)}
        labelCancel={t(`gallery.finding.dialog.menu.cancel`)}
        intent="primary"
      >
        <Text type="display2" display="block" marginBottom="m">
          {tPrint('gallery.finding.dialog.menu.title', matchingFindingIndex)}
        </Text>
        <Text renderHtml display="block">
          {tPrint('gallery.finding.dialog.menu.content', matchingFindingIndex)}
        </Text>
      </DialogConfirm>
    );
  }

  return (
    <Box
      data-thumbnail
      display="flex"
      className={styles.thumbnailBox}
      onClick={e => e.stopPropagation()}
    >
      <Card padding="none" flex="1">
        <Box className={isFullscreen ? styles.thumbnailFull : styles.thumbnail}>
          <Box className={styles.imageContainer}>
            <Image
              data-testid={dicom.id}
              src={imageSrc}
              roundedCorners={false}
              fluid
              ratio="landscape"
              loading="lazy"
            />
            {showSegmentationOverlay && (
              <ThumbnailCanvas
                dicom={dicom}
                detections={detections}
                toggleMenu={() => setShowMenu(show => !show)}
                setMenuPosition={setMenuPosition}
                showMenu={showMenu}
                filters={filters}
                setActiveDetectionId={setActiveDetectionId}
                showMeasurements={showMeasurements && !isLoadingImages}
              />
            )}
            <ThumbnailIcon
              isFullscreen={isFullscreen}
              handleSetView={handleSetView}
              view={view}
              index={index}
            />

            {activeDetection && showMenu && menuPosition && (
              <FindingMenu
                showMenu={showMenu}
                menuPosition={menuPosition}
                setShowMenu={setShowMenu}
                activeDetection={activeDetection}
                handleUpdateFinding={handleUpdateFinding}
                handleAddFinding={handleAddFinding}
                handleUnassignFromFinding={handleUnassignFromFinding}
                setLastUnassignedDetection={setLastUnassignedDetection}
              />
            )}

            <DebugOverlay dicom={dicom} debugDicom={debugDicom} />
          </Box>
        </Box>
      </Card>
    </Box>
  );
};

function getDetectionSizes(detection: Detection, findingSize3D: boolean) {
  if (findingSize3D) {
    return detection.detectionSizes;
  } else {
    const clonedDetectionSizes = [...detection.detectionSizes];
    const max = clonedDetectionSizes.sort((a, b) => a.size - b.size).pop();
    return max ? [max] : [];
  }
}

function getDetectionsForThumbnail(dicom: Dicom, findingSize3D: boolean) {
  return dicom.detections.map(detection => ({
    ...detection,
    detectionSizes: getDetectionSizes(detection, findingSize3D),
  }));
}
