import React, { useEffect, useRef, useState } from 'react';

interface ScaledContainerProps {
  referenceWidth: number;
  paddingTop?: number;
  paddingBottom?: number;
}

export const ScaledContainer: React.FC<ScaledContainerProps> = ({
  children,
  referenceWidth,
  paddingTop = 0,
  paddingBottom = 0,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [scale, setScale] = useState(1);

  useEffect(() => {
    // Scale the content based on the container width relative to the referenceWidth
    // This ensures that even when scaled, the layout of the content will not change
    const resizeContent = () => {
      if (containerRef.current) {
        setScale(containerRef.current.offsetWidth / referenceWidth);
      }
    };

    resizeContent();

    const resizeObserver = new ResizeObserver(resizeContent);
    if (containerRef.current) {
      resizeObserver.observe(containerRef.current, { box: 'content-box' });
    }
    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  return (
    <div className="size-full" ref={containerRef}>
      <div
        className="size-full h-0 origin-top-left"
        style={{
          transform: `scale(${scale})`,
        }}
      >
        <div
          style={{
            paddingTop: `${paddingTop / scale}px`,
            paddingBottom: `${paddingBottom / scale}px`,
          }}
        >
          {children}
        </div>
      </div>
    </div>
  );
};
