import React, { Suspense } from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@webMolecules/Box/Box';
import { LoadingIcon } from '@webMolecules/LoadingIcon/LoadingIcon';
import { cnames } from '@helpers/cnames';
import { Icon } from '@webMolecules/Icon/Icon';
import { SessionConfiguration } from '@interfaces/SessionLineItem';
import {
  ColourPalette,
  ICanvasItem,
  UpdatedObjectEventData,
} from '@appCanvas/interfaces/types';
import { LoadingLayout } from '@webPages/Study/LoadingLayout';
import { selectThyroidGeneralCharacteristics } from '@selectors/SessionSelectors';
import { useDispatcher } from '@helpers/useDispatcher';
import { findingUseCase } from '@useCases/Finding';
import { Button } from '@webMolecules/Button/Button';
import { Annotations } from '@entities/Dicom';
import { ScrollBox } from '@webMolecules/ScrollBox/ScrollBox';
import { getMeasurementsFromGeneralCharacteristics } from './ReportCanvas/Helper';
import { GeneralCharacteristicsOverlay } from './GeneralCharacteristicsOverlay';

const MemoizedPistachioReportCanvas = React.lazy(
  () => import('./ReportCanvas/PistachioReportCanvas')
);

export interface DiagramProps {
  canvasColorPalette: ColourPalette;
  onDeleteCanvasObject: (canvasItem: ICanvasItem) => void;
  loading: boolean;
  sessionConfiguration?: SessionConfiguration;
  dispatchUpdateConfiguration: (configuration: SessionConfiguration) => void;
  isPreviousCanvas: boolean;
  showCanvasOnly: boolean;
  disableInteractions?: boolean;
  canvasItems: ICanvasItem[];
  setShowGeneralTable?: (show: boolean) => void;
}

export interface MenuType {
  show: boolean;
  annotations: Annotations;
}

export const ReportDiagram: React.FC<DiagramProps> = ({
  canvasColorPalette,
  onDeleteCanvasObject,
  loading,
  isPreviousCanvas,
  showCanvasOnly,
  disableInteractions,
  canvasItems,
  setShowGeneralTable,
}) => {
  const generalCharacteristics = useSelector(
    selectThyroidGeneralCharacteristics
  );
  const updateFindingFromCanvas = useDispatcher(
    findingUseCase.UpdateFindingFromCanvas
  );

  const onCanvasFindingUpdate = (
    id: string,
    updatedEventData: UpdatedObjectEventData
  ) => {
    updateFindingFromCanvas({ findingId: id, updatedEventData });
  };

  const measurements = getMeasurementsFromGeneralCharacteristics(
    generalCharacteristics
  );

  if (showCanvasOnly) {
    return (
      <Suspense fallback={<LoadingLayout />}>
        <MemoizedPistachioReportCanvas
          isPreviousCanvas={isPreviousCanvas}
          canvasItems={canvasItems}
          colourPalette={canvasColorPalette}
          disableInteractions={disableInteractions ?? true}
          onDeleteCanvasObject={onDeleteCanvasObject}
          measurements={measurements}
          onCanvasFindingUpdate={onCanvasFindingUpdate}
        />
      </Suspense>
    );
  }
  const canvas = (
    <Suspense fallback={<LoadingLayout />}>
      <MemoizedPistachioReportCanvas
        isPreviousCanvas={isPreviousCanvas}
        canvasItems={canvasItems}
        colourPalette={canvasColorPalette}
        disableInteractions={disableInteractions ?? false}
        onDeleteCanvasObject={onDeleteCanvasObject}
        onCanvasFindingUpdate={onCanvasFindingUpdate}
        measurements={measurements}
      />
    </Suspense>
  );

  return (
    <Box onClick={e => e.stopPropagation()}>
      <div
        className="relative mb-s rounded-m border-2 border-black/[0.01] shadow-[0_0_0_4px_var(--ds-border-color-weak)] "
        data-testid="diagram"
      >
        <Box className={cnames({ 'opacity-0': loading })}>
          {measurements && (
            <Box className="absolute right-0 z-[999] rounded-bl-m rounded-tr-m bg-neutral-500 py-xxxs pl-xxxs">
              <Button
                iconBefore={<Icon name="edit" />}
                size="small"
                onClick={() => setShowGeneralTable && setShowGeneralTable(true)}
              />
            </Box>
          )}

          <ScrollBox axis="x">
            <GeneralCharacteristicsOverlay
              generalCharacteristics={generalCharacteristics}
            >
              {canvas}
            </GeneralCharacteristicsOverlay>
          </ScrollBox>
        </Box>
        {loading && (
          <Box className="absolute inset-0 flex items-center justify-center">
            <LoadingIcon />
          </Box>
        )}
      </div>
    </Box>
  );
};
