import React, { Fragment, useContext, useState } from 'react';
import { fabric } from 'fabric';
import { useSelector } from 'react-redux';
import { Dicom } from '@interfaces/Dicom';
import {
  drawBoundingBox,
  drawBoundingBoxIndex,
  drawAxis,
  drawBoundingBoxConfidence,
} from '@webViewInterfaces/Study/Gallery';
import { GalleryFilter } from '@interfaces/GalleryFilter';
import { Position } from '@webMolecules/PositionedBox/PositionedBox';
import {
  selectConfig,
  selectShowBoundingBoxConfidence,
} from '@selectors/BaseSelectors';
import {
  selectFilterId,
  selectHoveredMeasurement,
} from '@selectors/FindingInteractionSelectors';
import { Detection, DetectionSize } from '@entities/Detection';
import { selectFindingSizeReferences } from '@selectors/FindingSelectors';
import { ResizableSplitPanelContext } from '@webMolecules/ResizableSplitPanel/ResizableSplitPanel';
import { DistanceUnit } from '@entities/Unit';
import { convertAndRoundByUnit } from '@handlers/UnitHandler';

export const ThumbnailCanvas = ({
  detections,
  dicom,
  showMenu,
  showMeasurements,
  toggleMenu,
  setMenuPosition,
  setActiveDetectionId,
  filters,
}: {
  showMenu: boolean;
  filters: GalleryFilter;
  detections: Detection[];
  dicom: Dicom;
  showMeasurements: boolean;
  toggleMenu: () => void;
  setMenuPosition: (menuPosition: Position) => void;
  setActiveDetectionId: (detectionId: string) => void;
}) => {
  const { isResizing: isGalleryResizing } = useContext(
    ResizableSplitPanelContext
  );

  const canvasRef = React.useRef<HTMLCanvasElement>(null);
  const canvasContainerRef = React.useRef<HTMLDivElement>(null);

  const fabricCanvasRef = React.useRef<fabric.Canvas | null>(null);

  const [hoveredDetectionId, setHoveredDetectionId] = useState<string | null>(
    null
  );

  const sizeReferences = useSelector(selectFindingSizeReferences);
  const findingFilterId = useSelector(selectFilterId);
  const hoveredMeasurement = useSelector(selectHoveredMeasurement);
  const debugShowBoundingBoxConfidence = useSelector(
    selectShowBoundingBoxConfidence
  );
  const { noduleSizeDisplayUnit } = useSelector(selectConfig);

  const sizeReferencesRef = React.useRef<string[]>(sizeReferences);

  React.useEffect(() => {
    if (canvasRef.current && canvasContainerRef.current) {
      const fabricCanvas = new fabric.Canvas(canvasRef.current, {
        containerClass: '!w-full !h-full',
        width: canvasContainerRef.current.offsetWidth,
        height: canvasContainerRef.current.offsetHeight,
        preserveObjectStacking: true,
      });

      fabricCanvasRef.current = fabricCanvas;
    }

    return () => {
      fabricCanvasRef.current?.dispose();
    };
  }, []);

  React.useEffect(() => {
    const resizeObserver = new ResizeObserver(onResize);
    if (canvasRef.current && !isGalleryResizing) {
      resizeObserver.observe(canvasRef.current, { box: 'content-box' });
    }
    return () => {
      resizeObserver.disconnect(); // Clean up after finding ids change
    };
  }, [canvasRef, filters.findingIds, isGalleryResizing]); // Add findingIds to dependency array

  React.useEffect(() => {
    sizeReferencesRef.current = sizeReferences;
    renderDetection();
  }, [
    detections,
    showMeasurements,
    hoveredDetectionId,
    hoveredMeasurement,
    sizeReferences,
    debugShowBoundingBoxConfidence,
  ]);

  React.useEffect(() => {
    if (!showMenu && fabricCanvasRef.current) {
      fabricCanvasRef.current.discardActiveObject();
    }
  }, [showMenu]);

  const onResize = (entries: any) => {
    for (const entry of entries) {
      let width;
      let height;
      let dpr = window.devicePixelRatio;
      if (entry.devicePixelContentBoxSize) {
        // NOTE: Only this path gives the correct answer
        // The other 2 paths are an imperfect fallback
        // for browsers that don't provide anyway to do this
        width = entry.devicePixelContentBoxSize[0].inlineSize;
        height = entry.devicePixelContentBoxSize[0].blockSize;
        dpr = 1; // it's already in width and height
      } else if (entry.contentBoxSize) {
        if (entry.contentBoxSize[0]) {
          width = entry.contentBoxSize[0].inlineSize;
          height = entry.contentBoxSize[0].blockSize;
        } else {
          // legacy
          width = entry.contentBoxSize.inlineSize;
          height = entry.contentBoxSize.blockSize;
        }
      } else {
        // legacy
        width = entry.contentRect.width;
        height = entry.contentRect.height;
      }
      const displayWidth = Math.round(width * dpr);
      const displayHeight = Math.round(height * dpr);

      fabricCanvasRef.current?.setDimensions({
        width: displayWidth,
        height: displayHeight,
      });
      renderDetection();
    }
  };

  const renderSegmentationAxes = (detection: Detection) => {
    if (fabricCanvasRef.current) {
      const zoomX = (fabricCanvasRef.current.width ?? 0) / dicom.width;
      const zoomY = (fabricCanvasRef.current.height ?? 0) / dicom.height;

      detection.detectionSizes
        .filter(
          detectionSize =>
            isAxisLinkedToSize(detectionSize.id, sizeReferencesRef.current) ||
            detection.id === hoveredDetectionId
        )
        .filter(
          detectionSize =>
            showMeasurements || detectionSize.id === hoveredMeasurement
        )
        .map(detectionSize =>
          convertDetectionSizeUnit(detectionSize, noduleSizeDisplayUnit)
        )
        .forEach(detectionSize => {
          const group = drawAxis(
            detection,
            detectionSize,
            detectionSize.id === hoveredMeasurement,
            zoomX,
            zoomY
          );
          if (group) {
            fabricCanvasRef.current?.add(group);
          }
        });
    }
  };

  const renderDetection = () => {
    if (detections && fabricCanvasRef.current) {
      fabricCanvasRef.current.clear();
      const zoomX = (fabricCanvasRef.current.width ?? 0) / dicom.width;
      const zoomY = (fabricCanvasRef.current.height ?? 0) / dicom.height;

      detections.forEach(detection => {
        if (
          findingFilterId &&
          detection.assignedFinding?.id !== findingFilterId
        ) {
          // Dont show detection if it doesn't belong to the filtered finding
          return;
        }

        const findingIndex = detection.assignedFinding?.index ?? '';

        const group = drawBoundingBox(
          fabricCanvasRef.current,
          detection,
          findingIndex,
          zoomX,
          zoomY
        );

        if (group) {
          group.on('mousedown', event => {
            if (event.e instanceof MouseEvent) {
              setActiveDetectionId(detection.id);
              setMenuPosition({
                x: event.e.offsetX,
                y: event.e.offsetY,
              });
              toggleMenu();
            }
          });
          if (showMeasurements) {
            group.on('mouseover', _ => {
              setHoveredDetectionId(detection.id);
            });

            group.on('mouseout', _ => {
              setHoveredDetectionId(null);
            });
          }

          fabricCanvasRef.current?.add(group);

          //Render index badge in relation to roi group.
          const indexBadge = drawBoundingBoxIndex(group, findingIndex);
          if (indexBadge) fabricCanvasRef.current?.add(indexBadge);

          // Render bounding box confidence
          if (debugShowBoundingBoxConfidence) {
            const confidence = drawBoundingBoxConfidence(group, detection);
            if (confidence) fabricCanvasRef.current?.add(confidence);
          }
        }
        if (
          (showMeasurements &&
            (detection.id === hoveredDetectionId || findingIndex !== '')) ||
          hoveredMeasurement
        ) {
          renderSegmentationAxes(detection);
        }
        fabricCanvasRef.current?.requestRenderAll();
      });
    }
  };

  return (
    <Fragment>
      <div
        ref={canvasContainerRef}
        key={`${dicom.id}-thumbnail-fabricCanvas}`}
        className="absolute inset-x-0 top-1/2 -translate-y-1/2"
        style={{ aspectRatio: `${dicom.width}/${dicom.height}` }}
      >
        <canvas ref={canvasRef}></canvas>
      </div>
    </Fragment>
  );
};

function isAxisLinkedToSize(id: string, sizeReferences: string[]) {
  return sizeReferences.includes(id);
}

function convertDetectionSizeUnit(
  { size, annotatedSize, unit, ...rest }: DetectionSize,
  targetUnit: DistanceUnit
): DetectionSize {
  return {
    size: convertAndRoundByUnit(size, unit, targetUnit),
    annotatedSize:
      annotatedSize && convertAndRoundByUnit(annotatedSize, unit, targetUnit),
    unit: targetUnit,
    ...rest,
  };
}
